"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresetItem = void 0;
var preact_1 = require("preact");
var preset_item_data_1 = require("../models/preset-item-data");
var injected_component_1 = require("@playkit-js/common/dist/ui-common/injected-component");
var PresetItem = /** @class */ (function () {
    function PresetItem(options) {
        var _this = this;
        this._render = function () {
            if (_this._options.data.isolateComponent) {
                var _a = _this._options.data, label = _a.label, fillContainer = _a.fillContainer;
                return ((0, preact_1.h)(injected_component_1.InjectedComponent, { label: label, fillContainer: fillContainer || false, onCreate: _this._onCreate, onDestroy: _this._onDestroy }));
            }
            return _this._options.data.renderChild();
        };
        this._onDestroy = function (options) {
            // TODO sakal handle destroy
            if (!options.parent) {
                return;
            }
            (0, preact_1.render)(null, options.parent);
        };
        this._onCreate = function (options) {
            try {
                if (!options.parent) {
                    return;
                }
                var child = _this._options.data.renderChild();
                if (!child) {
                    return;
                }
                (0, preact_1.render)(child, options.parent);
            }
            catch (error) {
                /**/
            }
        };
        this._options = options;
    }
    Object.defineProperty(PresetItem.prototype, "playerConfig", {
        get: function () {
            var configs = [];
            for (var presetType in this._options.data.presetAreas) {
                var presetContainer = this._options.data.presetAreas[presetType];
                var relativeTo = this._options.data.relativeTo;
                if (!presetContainer) {
                    continue;
                }
                var result = {
                    label: this._options.data.label,
                    presets: [presetType],
                    container: presetContainer,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    get: this._render
                };
                if (relativeTo) {
                    switch (relativeTo.type) {
                        case preset_item_data_1.RelativeToTypes.After:
                            result['afterComponent'] = relativeTo.name;
                            break;
                        case preset_item_data_1.RelativeToTypes.Before:
                            result['beforeComponent'] = relativeTo.name;
                            break;
                        case preset_item_data_1.RelativeToTypes.Replace:
                            result['replaceComponent'] = relativeTo.name;
                            break;
                    }
                }
                configs.push(result);
            }
            return configs;
        },
        enumerable: false,
        configurable: true
    });
    return PresetItem;
}());
exports.PresetItem = PresetItem;
