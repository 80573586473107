"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayedBar = void 0;
var preact_1 = require("preact");
var icon_wrapper_component_1 = require("../icon-wrapper/icon-wrapper.component");
var styles = __importStar(require("./displayed-bar.component.scss"));
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var more_icon_component_1 = require("../more-icon/more-icon.component");
var PLAYER_SIZE = kaltura_player_js_1.ui.Components.PLAYER_SIZE;
var connect = kaltura_player_js_1.ui.redux.connect;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
var mapStateToProps = function (state) { return ({
    playerSize: state.shell.playerSize
}); };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
var DisplayedBar = /** @class */ (function (_super) {
    __extends(DisplayedBar, _super);
    function DisplayedBar() {
        var _this = _super.call(this) || this;
        _this.handleOnClick = function () {
            _this.setState(function (prevState) { return ({ showDropdown: !prevState.showDropdown }); });
        };
        _this.update = function () {
            _this.forceUpdate();
        };
        _this.state = { showDropdown: false };
        return _this;
    }
    DisplayedBar.prototype.closeDropdown = function () {
        this.setState({ showDropdown: false });
    };
    DisplayedBar.prototype.splitControlsIntoDisplayedAndDropdown = function () {
        switch (this.props.playerSize) {
            case PLAYER_SIZE.TINY:
                return { displayedControls: [], dropdownControls: [] };
            case PLAYER_SIZE.EXTRA_SMALL:
            case PLAYER_SIZE.SMALL:
                return this.splitControls(2);
            default:
                return this.splitControls(4);
        }
    };
    DisplayedBar.prototype.splitControls = function (numberOfDisplayedIcon) {
        var displayedControls;
        var dropdownControls;
        var controls = this.props.getControls();
        if (controls.length > numberOfDisplayedIcon + 1) {
            displayedControls = controls.slice(0, numberOfDisplayedIcon);
            dropdownControls = controls.slice(numberOfDisplayedIcon);
        }
        else {
            displayedControls = controls;
            dropdownControls = [];
        }
        return { displayedControls: displayedControls, dropdownControls: dropdownControls };
    };
    DisplayedBar.prototype.render = function () {
        var _this = this;
        var _a = this.splitControlsIntoDisplayedAndDropdown(), displayedControls = _a.displayedControls, dropdownControls = _a.dropdownControls;
        return displayedControls.length > 0 ? ((0, preact_1.h)("div", { className: styles.rightUpperBarWrapperContainer },
            displayedControls.map(function (_a) {
                var id = _a.id, component = _a.component, onClick = _a.onClick, componentRef = _a.componentRef;
                var IconWrapperComponent = component;
                return ((0, preact_1.h)(icon_wrapper_component_1.IconWrapper, { key: id, onClick: function () {
                        var e = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            e[_i] = arguments[_i];
                        }
                        onClick.apply(void 0, __spreadArray([], __read(e), false));
                        _this.closeDropdown();
                    }, ref: componentRef },
                    (0, preact_1.h)(IconWrapperComponent, null)));
            }),
            dropdownControls.length > 0 && ((0, preact_1.h)(more_icon_component_1.MoreIcon, { showDropdown: this.state.showDropdown, onClick: this.handleOnClick, icons: dropdownControls })))) : undefined;
    };
    DisplayedBar = __decorate([
        connect(mapStateToProps, null, null, { forwardRef: true })
    ], DisplayedBar);
    return DisplayedBar;
}(preact_1.Component));
exports.DisplayedBar = DisplayedBar;
