// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playkit-toastsContainer_Ev{position:absolute;min-width:120px;max-width:310px;display:flex;flex-direction:column;z-index:2}.playkit-toastsContainer_Ev.playkit-top-right_fC{right:0;top:0}.playkit-toastsContainer_Ev.playkit-top-left_Pi{left:0;top:0}.playkit-toastsContainer_Ev.playkit-top-left_Pi .playkit-toastRow_Va{align-self:flex-start}.playkit-toastsContainer_Ev.playkit-bottom-right_KT{right:0;bottom:0}.playkit-toastsContainer_Ev.playkit-bottom-left_Dx{left:0;bottom:0}.playkit-toastsContainer_Ev.playkit-bottom-left_Dx .playkit-toastRow_Va{align-self:flex-start}.playkit-toastRow_Va{height:58px;min-width:120px;max-width:310px;margin-top:8px;overflow:hidden;overflow-wrap:break-word;text-overflow:ellipsis;align-self:flex-end}", "",{"version":3,"sources":["webpack://./src/services/toast-manager/ui/toasts-container/toasts-container.scss"],"names":[],"mappings":"AAAA,4BAAiB,iBAAiB,CAAC,eAAe,CAAC,eAAe,CAAC,YAAY,CAAC,qBAAqB,CAAC,SAAS,CAAC,iDAA2B,OAAO,CAAC,KAAK,CAAC,gDAA0B,MAAM,CAAC,KAAK,CAAC,qEAAoC,qBAAqB,CAAC,oDAA8B,OAAO,CAAC,QAAQ,CAAC,mDAA6B,MAAM,CAAC,QAAQ,CAAC,wEAAuC,qBAAqB,CAAC,qBAAU,WAAW,CAAC,eAAe,CAAC,eAAe,CAAC,cAAc,CAAC,eAAe,CAAC,wBAAwB,CAAC,sBAAsB,CAAC,mBAAmB","sourcesContent":[".toastsContainer{position:absolute;min-width:120px;max-width:310px;display:flex;flex-direction:column;z-index:2}.toastsContainer.top-right{right:0;top:0}.toastsContainer.top-left{left:0;top:0}.toastsContainer.top-left .toastRow{align-self:flex-start}.toastsContainer.bottom-right{right:0;bottom:0}.toastsContainer.bottom-left{left:0;bottom:0}.toastsContainer.bottom-left .toastRow{align-self:flex-start}.toastRow{height:58px;min-width:120px;max-width:310px;margin-top:8px;overflow:hidden;overflow-wrap:break-word;text-overflow:ellipsis;align-self:flex-end}"],"sourceRoot":""}]);
// Exports
export var toastsContainer = "playkit-toastsContainer_Ev";
export var topRight = "playkit-top-right_fC";
export var topLeft = "playkit-top-left_Pi";
export var toastRow = "playkit-toastRow_Va";
export var bottomRight = "playkit-bottom-right_KT";
export var bottomLeft = "playkit-bottom-left_Dx";
export default ___CSS_LOADER_EXPORT___;
