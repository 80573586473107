// EventVar 1 in kava server

export enum ButtonType {
  Create = 1,
  Filter = 2,
  Search = 3,
  Export = 4,
  Navigate = 5,
  Schedule = 6,
  Insert = 7,
  Choose = 8,
  Launch = 9,
  Open = 10,
  Send = 11,
  Invite = 12,
  Close = 13,
  Save = 14,
  Expand = 15,
  Collapse = 16,
  Edit = 17,
  Delete = 18,
  Browse = 19,
  Load = 20,
  Add = 21,
  Menu = 22,
  Register = 23,
  Login = 24,
  Link = 25,
  Toggle = 26,
  Thumbnail = 27,
  Download = 28,
  Share = 29
}
