"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIManagers = exports.pluginName = void 0;
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var side_panels_manager_1 = require("./services/side-panels-manager/side-panels-manager");
var upper_bar_manager_1 = require("./services/upper-bar-manager/upper-bar-manager");
var floating_manager_1 = require("./services/floating-manager/floating-manager");
var preset_manager_1 = require("./services/preset-manager/preset-manager");
var toast_manager_1 = require("./services/toast-manager/toast-manager");
var banner_manager_1 = require("./services/banner-manager/banner-manager");
exports.pluginName = 'uiManagers';
/**
 * manages the registration of UI services
 * @internal
 */
var UIManagers = /** @class */ (function (_super) {
    __extends(UIManagers, _super);
    function UIManagers(name, player, config) {
        var _this = _super.call(this, name, player, config) || this;
        player.registerService('sidePanelsManager', new side_panels_manager_1.SidePanelsManager(player, _this.logger));
        player.registerService('upperBarManager', new upper_bar_manager_1.UpperBarManager(player, _this.logger));
        var presetManager = new preset_manager_1.PresetManager({
            kalturaPlayer: player,
            eventManager: _this.eventManager
        });
        var floatingManager = new floating_manager_1.FloatingManager({
            presetManager: presetManager,
            kalturaPlayer: player,
            logger: _this.logger,
            eventManager: _this.eventManager
        });
        player.registerService('floatingManager', floatingManager);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        player.registerService('toastManager', new toast_manager_1.ToastManager({ floatingManager: floatingManager }, function (event) { return _this.dispatchEvent(event); }));
        player.registerService('bannerManager', new banner_manager_1.BannerManager({ floatingManager: floatingManager, kalturaPlayer: player }));
        return _this;
    }
    UIManagers.isValid = function () {
        return true;
    };
    UIManagers.defaultConfig = {};
    return UIManagers;
}(kaltura_player_js_1.BasePlugin));
exports.UIManagers = UIManagers;
