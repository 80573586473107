(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@playkit-js/playkit-js"), require("hls.js"));
	else if(typeof define === 'function' && define.amd)
		define("playkit", ["playkit-js", "hls.js"], factory);
	else if(typeof exports === 'object')
		exports["playkit"] = factory(require("@playkit-js/playkit-js"), require("hls.js"));
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["hls"] = factory(root["playkit"]["core"], root["Hls"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__952__, __WEBPACK_EXTERNAL_MODULE__85__) {
return 