"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresetManager = exports.PresetManagerEventTypes = void 0;
var preact_1 = require("preact");
var events_manager_1 = require("@playkit-js/common/dist/ui-common/events-manager");
var ui_player_adapter_1 = require("./ui-player-adapter");
var preset_item_1 = require("./ui/preset-item");
var PresetManagerEventTypes;
(function (PresetManagerEventTypes) {
    PresetManagerEventTypes["PresetResizeEvent"] = "PresetResizeEvent";
    PresetManagerEventTypes["VideoResizeEvent"] = "VideoResizeEvent";
})(PresetManagerEventTypes = exports.PresetManagerEventTypes || (exports.PresetManagerEventTypes = {}));
var PresetManager = /** @class */ (function () {
    function PresetManager(options) {
        var _this = this;
        this._events = new events_manager_1.EventsManager();
        this._registerToPlayer = function () {
            _this._eventManager.listen(_this._kalturaPlayer, _this._kalturaPlayer.Event.UI.UI_PRESET_RESIZE, _this._notifyUIPresetResize);
            _this._eventManager.listen(_this._kalturaPlayer, _this._kalturaPlayer.Event.UI.VIDEO_RESIZE, _this._notifyVideoResize);
        };
        this._notifyVideoResize = function () {
            _this._events.emit({
                type: PresetManagerEventTypes.VideoResizeEvent
            });
        };
        this._notifyUIPresetResize = function () {
            _this._events.emit({
                type: PresetManagerEventTypes.PresetResizeEvent
            });
        };
        this._unregisterToPlayer = function () {
            _this._eventManager.unlisten(_this._kalturaPlayer, _this._kalturaPlayer.Event.UI.UI_PRESET_RESIZE, _this._notifyUIPresetResize);
            _this._eventManager.unlisten(_this._kalturaPlayer, _this._kalturaPlayer.Event.UI.VIDEO_RESIZE, _this._notifyVideoResize);
        };
        this.on = this._events.on.bind(this._events);
        this.off = this._events.off.bind(this._events);
        this._eventManager = options.eventManager;
        this._kalturaPlayer = options.kalturaPlayer;
        this.add({
            label: 'preset-manager',
            presetAreas: { Playback: 'PlayerArea', Live: 'PlayerArea' },
            renderChild: function () { return ((0, preact_1.h)(ui_player_adapter_1.UIPlayerAdapter, { player: options.kalturaPlayer, onMount: _this._registerToPlayer, onUnmount: _this._unregisterToPlayer })); }
        });
    }
    PresetManager.prototype.add = function (data) {
        var e_1, _a;
        var component = new preset_item_1.PresetItem({
            kalturaPlayer: this._kalturaPlayer,
            data: data
        });
        var configs = component.playerConfig;
        try {
            for (var configs_1 = __values(configs), configs_1_1 = configs_1.next(); !configs_1_1.done; configs_1_1 = configs_1.next()) {
                var config = configs_1_1.value;
                var label = config.label, presets = config.presets, container = config.container, get = config.get;
                this._kalturaPlayer.ui.addComponent({
                    label: label,
                    presets: presets,
                    container: container,
                    get: get
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (configs_1_1 && !configs_1_1.done && (_a = configs_1.return)) _a.call(configs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return PresetManager;
}());
exports.PresetManager = PresetManager;
