"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Banner = void 0;
var preact_1 = require("preact");
var styles = __importStar(require("./banner.scss"));
var someone_asks_large_1 = require("./someone-asks-large");
var someone_asks_small_1 = require("./someone-asks-small");
var Banner = /** @class */ (function (_super) {
    __extends(Banner, _super);
    function Banner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Banner.prototype.render = function (_a) {
        var content = _a.content;
        var text = content.text, _b = content.title, title = _b === void 0 ? 'Audience asks:' : _b, _c = content.icon, icon = _c === void 0 ? this._defaultIcon() : _c;
        return ((0, preact_1.h)("div", { className: styles.defaultBannerRoot + ' ' + styles.bannerWrapper },
            (0, preact_1.h)("div", { className: styles.iconContainer },
                (0, preact_1.h)("div", { className: styles.iconWrapper }, icon)),
            (0, preact_1.h)("div", { className: styles.bannerBody },
                (0, preact_1.h)("div", { className: styles.title }, title),
                (0, preact_1.h)("div", { className: styles.text }, text))));
    };
    Banner.prototype._defaultIcon = function () {
        return ((0, preact_1.h)("div", { className: styles.iconImage },
            (0, preact_1.h)(someone_asks_large_1.SomeoneAsksLarge, { className: styles.large }),
            (0, preact_1.h)(someone_asks_small_1.SomeoneAsksSmall, { className: styles.small })));
    };
    return Banner;
}(preact_1.Component));
exports.Banner = Banner;
