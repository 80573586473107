"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpperBarManager = void 0;
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var icon_model_1 = require("./models/icon-model");
var preact_1 = require("preact");
var displayed_bar_component_1 = require("./ui/displayed-bar/displayed-bar.component");
var ReservedPresetAreas = kaltura_player_js_1.ui.ReservedPresetAreas, ReservedPresetNames = kaltura_player_js_1.ui.ReservedPresetNames;
var UPPER_BAR_PRESETS = Object.values(ReservedPresetNames).filter(function (preset) { return preset !== ReservedPresetNames.Idle && preset !== ReservedPresetNames.Error; });
var UpperBarManager = /** @class */ (function () {
    /**
     * @ignore
     */
    function UpperBarManager(player, logger) {
        var _this = this;
        this.player = player;
        this.componentsRegistry = new Map();
        this.logger = logger;
        this.displayedBarComponentRefs = {};
        this.iconsOrder = {};
        UPPER_BAR_PRESETS.forEach(function (preset) { return (_this.displayedBarComponentRefs[preset] = (0, preact_1.createRef)()); });
        this.injectDisplayedBarComponentWrapper();
    }
    UpperBarManager.prototype.add = function (icon) {
        var _this = this;
        if (UpperBarManager.validateItem(icon)) {
            var newIcon = new icon_model_1.IconModel(icon);
            this.componentsRegistry.set(newIcon.id, newIcon);
            this.iconsOrder[icon.displayName] = icon.order;
            newIcon.presets.forEach(function (preset) { var _a; return (_a = _this.displayedBarComponentRefs[preset].current) === null || _a === void 0 ? void 0 : _a.update(); });
            this.logger.debug("control '".concat(newIcon.displayName, "' added, id: '").concat(newIcon.id, "' "));
            return newIcon.id;
        }
        this.logger.error('icon cannot be added due to invalid parameters', JSON.stringify(icon));
        return undefined;
    };
    UpperBarManager.prototype.remove = function (itemId) {
        var _this = this;
        var icon = this.componentsRegistry.get(itemId);
        if (icon) {
            this.componentsRegistry.delete(itemId);
            icon.presets.forEach(function (preset) { var _a; return (_a = _this.displayedBarComponentRefs[preset].current) === null || _a === void 0 ? void 0 : _a.update(); });
            this.logger.debug("control '".concat(icon.displayName, "' removed, id: '").concat(icon.id, "' "));
        }
        else {
            this.logger.warn("control ".concat(itemId, " is not registered"));
        }
    };
    UpperBarManager.prototype.isActive = function (itemId) {
        return !!this.componentsRegistry.get(itemId);
    };
    UpperBarManager.prototype.update = function (iconId) {
        var icon = this.componentsRegistry.get(iconId);
        if (icon) {
            icon.update();
        }
        else {
            this.logger.warn("control ".concat(iconId, " is not registered"));
        }
    };
    UpperBarManager.prototype.getControls = function (iconsOrder) {
        var icons = Array.from(this.componentsRegistry.values());
        return icons.sort(function (a, b) { return iconsOrder[a.displayName] - iconsOrder[b.displayName]; });
    };
    UpperBarManager.prototype.injectDisplayedBarComponentWrapper = function () {
        var e_1, _a;
        var _this = this;
        var iconsOrder = this.iconsOrder;
        var _loop_1 = function (preset) {
            this_1.player.ui.addComponent({
                label: 'Right-Upper-Bar-Wrapper',
                presets: [preset],
                area: ReservedPresetAreas.TopBarRightControls,
                get: function () {
                    return ((0, preact_1.h)(displayed_bar_component_1.DisplayedBar, { ref: _this.displayedBarComponentRefs[preset], getControls: function () { return _this.getControls(iconsOrder).filter(function (icon) { return icon.presets.includes(preset); }); } }));
                }
            });
        };
        var this_1 = this;
        try {
            for (var UPPER_BAR_PRESETS_1 = __values(UPPER_BAR_PRESETS), UPPER_BAR_PRESETS_1_1 = UPPER_BAR_PRESETS_1.next(); !UPPER_BAR_PRESETS_1_1.done; UPPER_BAR_PRESETS_1_1 = UPPER_BAR_PRESETS_1.next()) {
                var preset = UPPER_BAR_PRESETS_1_1.value;
                _loop_1(preset);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (UPPER_BAR_PRESETS_1_1 && !UPPER_BAR_PRESETS_1_1.done && (_a = UPPER_BAR_PRESETS_1.return)) _a.call(UPPER_BAR_PRESETS_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    UpperBarManager.validateItem = function (icon) {
        return typeof icon.onClick === 'function' && typeof icon.component === 'function';
    };
    return UpperBarManager;
}());
exports.UpperBarManager = UpperBarManager;
