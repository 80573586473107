"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelItemWrapper = void 0;
var preact_1 = require("preact");
var styles = __importStar(require("./panel-item-wrapper.component.scss"));
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var defaultTransitionTime = kaltura_player_js_1.ui.style.defaultTransitionTime;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
var createPortal = kaltura_player_js_1.ui.createPortal;
/**
 * PanelItemWrapper component
 * @internal
 */
var PanelItemWrapper = /** @class */ (function (_super) {
    __extends(PanelItemWrapper, _super);
    function PanelItemWrapper() {
        var _this = _super.call(this) || this;
        _this.detach = function (detachRef, attachPlaceholder) {
            _this.setState({ detachRef: detachRef, attachPlaceholder: attachPlaceholder });
        };
        _this.attach = function () {
            _this.setState({ detachRef: null, attachPlaceholder: function () { return null; } });
        };
        _this.state = {
            on: false,
            detachRef: null,
            attachPlaceholder: function () { return null; }
        };
        _this.switchMode = false;
        return _this;
    }
    PanelItemWrapper.prototype.on = function () {
        this.setState({ on: true });
    };
    PanelItemWrapper.prototype.off = function (switchMode) {
        this.switchMode = switchMode;
        this.setState({ on: false });
    };
    Object.defineProperty(PanelItemWrapper.prototype, "detachRef", {
        get: function () {
            return this.state.detachRef;
        },
        enumerable: false,
        configurable: true
    });
    PanelItemWrapper.prototype.render = function () {
        var node = (0, preact_1.cloneElement)(this.props.children);
        return ((0, preact_1.h)("div", { className: [styles.sidePanelWrapper, this.state.on ? styles.activeState : ''].join(' '), style: !this.state.on && !this.switchMode ? { transition: "visibility ".concat(defaultTransitionTime, "ms") } : '' }, this.detachRef ? ((0, preact_1.h)(preact_1.Fragment, null,
            createPortal(node, this.detachRef),
            (0, preact_1.h)(this.state.attachPlaceholder, null))) : (node)));
    };
    return PanelItemWrapper;
}(preact_1.Component));
exports.PanelItemWrapper = PanelItemWrapper;
