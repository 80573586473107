// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playkit-side-panel-wrapper_Wr{visibility:hidden;position:absolute;width:100%;height:100%}.playkit-active-state_vh{visibility:visible}", "",{"version":3,"sources":["webpack://./src/services/side-panels-manager/ui/panel-item-wrapper/panel-item-wrapper.component.scss"],"names":[],"mappings":"AAAA,+BAAoB,iBAAiB,CAAC,iBAAiB,CAAC,UAAU,CAAC,WAAW,CAAC,yBAAc,kBAAkB","sourcesContent":[".side-panel-wrapper{visibility:hidden;position:absolute;width:100%;height:100%}.active-state{visibility:visible}"],"sourceRoot":""}]);
// Exports
export var sidePanelWrapper = "playkit-side-panel-wrapper_Wr";
export var activeState = "playkit-active-state_vh";
export default ___CSS_LOADER_EXPORT___;
