"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconModel = void 0;
var preact_1 = require("preact");
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var ReservedPresetNames = kaltura_player_js_1.ui.ReservedPresetNames;
var IconModel = /** @class */ (function () {
    function IconModel(item) {
        this.id = ++IconModel.nextId;
        this.displayName = item.displayName;
        this.ariaLabel = item.ariaLabel;
        this.order = item.order;
        this.component = item.component;
        this.svgIcon = item.svgIcon;
        this.onClick = item.onClick;
        this.componentRef = (0, preact_1.createRef)();
        this.presets =
            item.presets && item.presets.length > 0 ? item.presets : [ReservedPresetNames.Playback, ReservedPresetNames.Live];
    }
    IconModel.prototype.update = function () {
        var _a;
        (_a = this.componentRef.current) === null || _a === void 0 ? void 0 : _a.forceUpdate();
    };
    IconModel.nextId = 0;
    return IconModel;
}());
exports.IconModel = IconModel;
