"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = void 0;
var preact_1 = require("preact");
var styles = __importStar(require("./toast.scss"));
var icon_1 = require("@playkit-js/common/dist/icon");
var Toast = /** @class */ (function (_super) {
    __extends(Toast, _super);
    function Toast() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isShown: true
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this._onClick = function (e) {
            _this.props.onClick();
            _this._onClose(e);
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this._onClose = function (e) {
            e.stopPropagation();
            _this.setState({ isShown: false });
            _this.props.onClose(_this.props.id);
        };
        return _this;
    }
    Toast.prototype._getToastSeverityClass = function () {
        switch (this.props.severity) {
            case 'Success':
                return styles.successToast;
            case 'Warning':
                return styles.warnToast;
            case 'Error':
                return styles.errorToast;
            default:
                //info
                return styles.infoToast;
        }
    };
    Toast.prototype.render = function () {
        var _a = this.props, text = _a.text, title = _a.title, icon = _a.icon;
        return ((0, preact_1.h)("div", { className: styles.toastWrapper + ' ' + this._getToastSeverityClass(), onClick: this._onClick },
            (0, preact_1.h)("button", { className: styles.closeButton, onClick: this._onClose },
                (0, preact_1.h)(icon_1.Icon, { size: icon_1.IconSize.small, name: "close" })),
            (0, preact_1.h)("div", { className: styles.title }, title),
            (0, preact_1.h)("div", { className: styles.toastBody },
                (0, preact_1.h)("div", { className: styles.iconContainer },
                    (0, preact_1.h)("div", { className: styles.iconWrapper }, icon)),
                (0, preact_1.h)("div", { className: styles.text }, text))));
    };
    return Toast;
}(preact_1.Component));
exports.Toast = Toast;
