// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sr-wrapper__sr-wrapper___GwVLr{position:absolute;height:1px;width:1px;overflow:hidden;padding:0;margin:-1px;border:0}", "",{"version":3,"sources":["webpack://./../node_modules/@playkit-js/common/dist/hoc/sr-wrapper/sr-wrapper.scss"],"names":[],"mappings":"AAAA,gCACE,iBAAA,CACA,UAAA,CACA,SAAA,CACA,eAAA,CACA,SAAA,CACA,WAAA,CACA,QAAA","sourcesContent":[".sr-wrapper {\n  position: absolute;\n  height: 1px;\n  width: 1px;\n  overflow: hidden;\n  padding: 0;\n  margin: -1px;\n  border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sr-wrapper": "sr-wrapper__sr-wrapper___GwVLr",
	"srWrapper": "sr-wrapper__sr-wrapper___GwVLr"
};
export default ___CSS_LOADER_EXPORT___;
