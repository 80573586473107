// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playkit-more-dropdown_SJ{position:absolute;padding:8px 4px;width:166px;background-color:var(--playkit-tone-7-color);border-radius:4px;top:44px;right:0}.playkit-more-dropdown_SJ .playkit-dropdown-item_Aq{border-radius:4px;padding:4px 12px 4px 15px;display:flex;margin:4px 0;cursor:pointer}.playkit-more-dropdown_SJ .playkit-dropdown-item_Aq .playkit-icon_h_{width:24px;height:24px;display:flex;align-items:center;justify-content:center}.playkit-more-dropdown_SJ .playkit-dropdown-item_Aq:hover{background-color:var(--playkit-tone-6-color)}.playkit-more-dropdown_SJ .playkit-dropdown-item_Aq .playkit-dropdown-item-description_rC{display:flex;flex:1;font-size:14px;font-weight:700;align-items:center;padding-left:11px}", "",{"version":3,"sources":["webpack://./src/services/upper-bar-manager/ui/dropdown-bar/dropdown-bar.component.scss"],"names":[],"mappings":"AAAA,0BAAe,iBAAiB,CAAC,eAAe,CAAC,WAAW,CAAC,4CAA4C,CAAC,iBAAiB,CAAC,QAAQ,CAAC,OAAO,CAAC,oDAA8B,iBAAiB,CAAC,yBAAyB,CAAC,YAAY,CAAC,YAAY,CAAC,cAAc,CAAC,qEAAoC,UAAU,CAAC,WAAW,CAAC,YAAY,CAAC,kBAAkB,CAAC,sBAAsB,CAAC,0DAAoC,4CAA4C,CAAC,0FAAyD,YAAY,CAAC,MAAM,CAAC,cAAc,CAAC,eAAe,CAAC,kBAAkB,CAAC,iBAAiB","sourcesContent":[".more-dropdown{position:absolute;padding:8px 4px;width:166px;background-color:var(--playkit-tone-7-color);border-radius:4px;top:44px;right:0}.more-dropdown .dropdown-item{border-radius:4px;padding:4px 12px 4px 15px;display:flex;margin:4px 0;cursor:pointer}.more-dropdown .dropdown-item .icon{width:24px;height:24px;display:flex;align-items:center;justify-content:center}.more-dropdown .dropdown-item:hover{background-color:var(--playkit-tone-6-color)}.more-dropdown .dropdown-item .dropdown-item-description{display:flex;flex:1;font-size:14px;font-weight:700;align-items:center;padding-left:11px}"],"sourceRoot":""}]);
// Exports
export var moreDropdown = "playkit-more-dropdown_SJ";
export var dropdownItem = "playkit-dropdown-item_Aq";
export var icon = "playkit-icon_h_";
export var dropdownItemDescription = "playkit-dropdown-item-description_rC";
export default ___CSS_LOADER_EXPORT___;
