"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastManager = void 0;
var preact_1 = require("preact");
var uuid_1 = require("@playkit-js/common/dist/ui-common/uuid");
var models_1 = require("./models");
var toasts_container_1 = require("./ui/toasts-container/toasts-container");
var ui_managers_event_1 = require("../../event-type/ui-managers-event");
var ToastManager = /** @class */ (function () {
    function ToastManager(options, dispatchEvent) {
        var _this = this;
        this.options = options;
        this.dispatchEvent = dispatchEvent;
        this._toasts = [];
        this._floatingItem = null;
        this._remove = function (id) {
            var index = _this._findToastIndexById(id);
            if (index === -1)
                return;
            clearTimeout(_this._toasts[index].timerSubscription);
            _this._toasts.splice(index, 1);
            _this._updateToastsUI();
            if (_this._toasts.length === 0)
                _this._removeToastsContainer();
        };
        this._options = options;
        this._dispatchEvent = dispatchEvent;
    }
    ToastManager.prototype.add = function (data) {
        var duration = data.duration, toastType = data.toastType, props = __rest(data, ["duration", "toastType"]);
        if (!this._floatingItem)
            this._addToastsContainer(toastType);
        var managedToast = {
            toastProps: __assign(__assign({}, props), { id: uuid_1.UUID.uuidV1(), onClose: this._remove }),
            duration: duration,
            timerSubscription: null
        };
        this._toasts.push(managedToast);
        this._updateToastsUI();
        this._startDurationTimer(managedToast);
        this.dispatchEvent(ui_managers_event_1.UiManagersEvent.UPDATE_COMPONENTS);
    };
    ToastManager.prototype.reset = function () {
        var _this = this;
        this._toasts.forEach(function (managedToast) {
            _this._remove(managedToast.toastProps.id);
        });
    };
    ToastManager.prototype._startDurationTimer = function (managedToast) {
        var _this = this;
        managedToast.timerSubscription = setTimeout(function () {
            _this._remove(managedToast.toastProps.id);
        }, managedToast.duration);
    };
    ToastManager.prototype._addToastsContainer = function (toastType) {
        var _this = this;
        this._floatingItem = this._options.floatingManager.add({
            label: 'Toasts',
            mode: 'Immediate',
            position: 'InteractiveArea',
            renderContent: function () {
                return ((0, preact_1.h)(toasts_container_1.ToastsContainer, { toastType: toastType || models_1.ToastType.BottomLeft, toasts: _this._toasts.map(function (toast) {
                        return toast.toastProps;
                    }) }));
            }
        });
    };
    ToastManager.prototype._removeToastsContainer = function () {
        if (!this._floatingItem)
            return;
        this._options.floatingManager.remove(this._floatingItem);
        this._floatingItem = null;
    };
    ToastManager.prototype._updateToastsUI = function () {
        if (this._floatingItem)
            this._floatingItem.update();
    };
    ToastManager.prototype._findToastIndexById = function (id) {
        var index = 0;
        while (index < this._toasts.length) {
            if (this._toasts[index].toastProps.id === id) {
                return index;
            }
            index++;
        }
        return -1;
    };
    return ToastManager;
}());
exports.ToastManager = ToastManager;
