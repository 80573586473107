"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastType = void 0;
var ToastType;
(function (ToastType) {
    ToastType["TopRight"] = "topRight";
    ToastType["TopLeft"] = "topLeft";
    ToastType["BottomRight"] = "bottomRight";
    ToastType["BottomLeft"] = "bottomLeft";
})(ToastType = exports.ToastType || (exports.ToastType = {}));
