// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._toasts-container__toastsContainer___ep0ad{position:absolute;right:0;top:0;padding:8px 16px 0;min-width:120px;max-width:310px;display:flex;flex-direction:column}._toasts-container__toastRow___J1NQa{height:42px;min-width:120px;max-width:310px;margin-bottom:8px;overflow:hidden;overflow-wrap:break-word;text-overflow:ellipsis;align-self:flex-end}", "",{"version":3,"sources":["webpack://./../node_modules/@playkit-js/common/dist/ui-common/toasts-container/_toasts-container.scss"],"names":[],"mappings":"AAAA,4CACE,iBAAA,CACA,OAAA,CACA,KAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CAGF,qCACE,WAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,sBAAA,CACA,mBAAA","sourcesContent":[".toastsContainer {\n  position: absolute;\n  right: 0;\n  top: 0;\n  padding: 8px 16px 0;\n  min-width: 120px;\n  max-width: 310px;\n  display: flex;\n  flex-direction: column;\n}\n\n.toastRow {\n  height: 42px;\n  min-width: 120px;\n  max-width: 310px;\n  margin-bottom: 8px;\n  overflow: hidden;\n  overflow-wrap: break-word;\n  text-overflow: ellipsis;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastsContainer": "_toasts-container__toastsContainer___ep0ad",
	"toastRow": "_toasts-container__toastRow___J1NQa"
};
export default ___CSS_LOADER_EXPORT___;
