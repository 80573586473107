// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon__iconWrapper___O5uqn.icon__small___IddLg{width:16px;height:16px}.icon__iconWrapper___O5uqn.icon__medium___llFxB{width:24px;height:24px}.icon__iconWrapper___O5uqn.icon__large___Q96VN{width:32px;height:32px}.icon__iconWrapper___O5uqn>svg{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../node_modules/@playkit-js/common/dist/icon/icon.scss"],"names":[],"mappings":"AACE,+CACE,UAAA,CACA,WAAA,CAEF,gDACE,UAAA,CACA,WAAA,CAEF,+CACE,UAAA,CACA,WAAA,CAEF,+BACE,UAAA,CACA,WAAA","sourcesContent":[".iconWrapper {\n  &.small {\n    width: 16px;\n    height: 16px;\n  }\n  &.medium {\n    width: 24px;\n    height: 24px;\n  }\n  &.large {\n    width: 32px;\n    height: 32px;\n  }\n  > svg {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": "icon__iconWrapper___O5uqn",
	"small": "icon__small___IddLg",
	"medium": "icon__medium___llFxB",
	"large": "icon__large___Q96VN"
};
export default ___CSS_LOADER_EXPORT___;
