"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatingManager = void 0;
var preact_1 = require("preact");
var preset_manager_1 = require("../preset-manager/preset-manager");
var managed_component_1 = require("../preset-manager/ui/managed-component");
var player_utils_1 = require("@playkit-js/common/dist/ui-common/player-utils");
var floating_item_1 = require("./ui/floating-item");
var ui_managers_event_1 = require("../../event-type/ui-managers-event");
var areaToPresetMapping = {
    VideoArea: {
        Live: 'VideoArea',
        Playback: 'VideoArea'
    },
    PresetArea: {
        Live: 'PresetArea',
        Playback: 'PresetArea'
    },
    InteractiveArea: {
        Live: 'InteractiveArea',
        Playback: 'InteractiveArea'
    }
};
var FloatingManager = /** @class */ (function () {
    function FloatingManager(_options) {
        var _this = this;
        this._options = _options;
        this._registered = false;
        this._items = {
            VideoArea: [],
            InteractiveArea: [],
            PresetArea: []
        };
        this._componentRef = {
            InteractiveArea: null,
            VideoArea: null,
            PresetArea: null
        };
        this._cache = {
            canvas: {
                playerSize: { width: 0, height: 0 },
                videoSize: { width: 0, height: 0 }
            }
        };
        this._renderItems = function (position) {
            var props = _this._getRendererProps({});
            return _this._items[position].map(function (item) { return item.renderFloatingChild(props); });
        };
        this._renderChild = function (position) {
            return ((0, preact_1.h)(managed_component_1.ManagedComponent, { label: 'floating-manager', renderChildren: function () { return _this._renderItems(position); }, isShown: function () { return true; }, ref: function (ref) { return (_this._componentRef[position] = ref); } }));
        };
        this._onTimeUpdate = function () {
            _this._updateComponents();
        };
        this._onMediaLoaded = function () {
            _this._updateCachedCanvas();
            _this._updateComponents();
        };
        this._onLoadedData = function () {
            _this._updateCachedCanvas();
            _this._updateComponents();
        };
        Object.keys(areaToPresetMapping).forEach(function (areaType) {
            // add floating manager in every preset
            _this._options.presetManager.add({
                label: 'floating-manager',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                presetAreas: areaToPresetMapping[areaType],
                renderChild: function () { return _this._renderChild(areaType); }
            });
        });
        this._logger = _options.logger;
        this._eventManager = _options.eventManager;
        this._addPlayerBindings();
        this._updateCachedCanvas();
    }
    /**
     * initialize new floating ui item
     * @param item
     */
    //TODO push new item to relevant position array according to its' FloatingPositions value
    FloatingManager.prototype.add = function (data) {
        var presetManager = this._options.presetManager;
        var itemOptions = __assign(__assign({ 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            presetManager: presetManager }, this._options), { data: data });
        var item = new floating_item_1.FloatingItem(itemOptions);
        this._items[data.position].push(item);
        return item;
    };
    FloatingManager.prototype.remove = function (item) {
        var positionItems = this._items[item.data.position];
        var itemIndex = positionItems.indexOf(item);
        if (itemIndex > -1) {
            positionItems[itemIndex].destroy();
            positionItems.splice(itemIndex, 1);
        }
        else {
            this._logger.warn("couldn't remove ".concat(item, " since it wasn't found"));
        }
    };
    FloatingManager.prototype.reset = function () {
        var _this = this;
        var allItems = __spreadArray(__spreadArray(__spreadArray([], __read(this._items.VideoArea), false), __read(this._items.InteractiveArea), false), __read(this._items.PresetArea), false);
        allItems.forEach(function (item) {
            try {
                item.destroy();
            }
            catch (e) {
                _this._logger.warn(e);
            }
        });
        this._items.VideoArea = [];
        this._items.PresetArea = [];
        this._items.InteractiveArea = [];
    };
    FloatingManager.prototype._getRendererProps = function (props) {
        var kalturaPlayer = this._options.kalturaPlayer;
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            currentTime: typeof props.currentTime !== 'undefined' ? props.currentTime : kalturaPlayer.currentTime * 1000,
            canvas: this._cache.canvas
        };
    };
    FloatingManager.prototype._updateCachedCanvas = function () {
        this._cache.canvas = {
            playerSize: (0, player_utils_1.getPlayerSize)(this._options.kalturaPlayer),
            videoSize: (0, player_utils_1.getVideoSize)(this._options.kalturaPlayer)
        };
    };
    FloatingManager.prototype._updateComponents = function () {
        if (this._componentRef.InteractiveArea) {
            this._componentRef.InteractiveArea.update();
        }
        if (this._componentRef.PresetArea) {
            this._componentRef.PresetArea.update();
        }
        if (this._componentRef.VideoArea) {
            this._componentRef.VideoArea.update();
        }
    };
    FloatingManager.prototype._addPlayerBindings = function () {
        var _this = this;
        var kalturaPlayer = this._options.kalturaPlayer;
        this._eventManager.listen(kalturaPlayer, kalturaPlayer.Event.Core.TIME_UPDATE, this._onTimeUpdate);
        this._eventManager.listen(kalturaPlayer, kalturaPlayer.Event.Core.MEDIA_LOADED, this._onMediaLoaded);
        this._eventManager.listen(kalturaPlayer, kalturaPlayer.Event.Core.LOADED_DATA, this._onLoadedData);
        this._options.presetManager.on(preset_manager_1.PresetManagerEventTypes.VideoResizeEvent, function () {
            _this._updateCachedCanvas();
            _this._updateComponents();
        });
        this._options.presetManager.on(preset_manager_1.PresetManagerEventTypes.PresetResizeEvent, function () {
            _this._updateCachedCanvas();
            _this._updateComponents();
        });
        this._eventManager.listen(kalturaPlayer, ui_managers_event_1.UiManagersEvent.UPDATE_COMPONENTS, function () { return _this._updateComponents(); });
    };
    return FloatingManager;
}());
exports.FloatingManager = FloatingManager;
