"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelativeToTypes = exports.ReservedPresetAreas = exports.ReservedPresetNames = void 0;
var ReservedPresetNames;
(function (ReservedPresetNames) {
    ReservedPresetNames["Playback"] = "Playback";
    ReservedPresetNames["Live"] = "Live";
})(ReservedPresetNames = exports.ReservedPresetNames || (exports.ReservedPresetNames = {}));
var ReservedPresetAreas;
(function (ReservedPresetAreas) {
    ReservedPresetAreas["PresetFloating"] = "PresetFloating";
    ReservedPresetAreas["BottomBarLeftControls"] = "BottomBarLeftControls";
    ReservedPresetAreas["BottomBarRightControls"] = "BottomBarRightControls";
    ReservedPresetAreas["TopBarLeftControls"] = "TopBarLeftControls";
    ReservedPresetAreas["TopBarRightControls"] = "TopBarRightControls";
    ReservedPresetAreas["SidePanelTop"] = "SidePanelTop";
    ReservedPresetAreas["SidePanelLeft"] = "SidePanelLeft";
    ReservedPresetAreas["SidePanelRight"] = "SidePanelRight";
    ReservedPresetAreas["SidePanelBottom"] = "SidePanelBottom";
    ReservedPresetAreas["PresetArea"] = "PresetArea";
    ReservedPresetAreas["InteractiveArea"] = "InteractiveArea";
    ReservedPresetAreas["PlayerArea"] = "PlayerArea";
    ReservedPresetAreas["VideoArea"] = "VideoArea";
})(ReservedPresetAreas = exports.ReservedPresetAreas || (exports.ReservedPresetAreas = {}));
var RelativeToTypes;
(function (RelativeToTypes) {
    RelativeToTypes["Before"] = "Before";
    RelativeToTypes["After"] = "After";
    RelativeToTypes["Replace"] = "Replace";
})(RelativeToTypes = exports.RelativeToTypes || (exports.RelativeToTypes = {}));
