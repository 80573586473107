"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatingItem = void 0;
var preact_1 = require("preact");
var managed_component_1 = require("../../preset-manager/ui/managed-component");
var FloatingItem = /** @class */ (function () {
    function FloatingItem(options) {
        var _this = this;
        this._isShown = false;
        this._componentRef = null;
        this.remove = function () {
            _this._isShown = false;
            if (!_this._componentRef) {
                return;
            }
            _this._componentRef.update();
        };
        this.add = function () {
            _this._isShown = true;
            if (!_this._componentRef) {
                return;
            }
            _this._componentRef.update();
        };
        this.update = function () {
            if (!_this._componentRef) {
                return;
            }
            _this._componentRef.update();
        };
        this._options = options;
        this._addPlayerBindings();
    }
    Object.defineProperty(FloatingItem.prototype, "data", {
        get: function () {
            return this._options.data;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * destory the ui item
     */
    FloatingItem.prototype.destroy = function () {
        this.remove();
    };
    FloatingItem.prototype.renderFloatingChild = function (props) {
        var _this = this;
        var label = this._options.data.label;
        return ((0, preact_1.h)(managed_component_1.ManagedComponent, { label: label, renderChildren: function () { return _this._options.data.renderContent(props); }, isShown: function () { return _this._isShown; }, ref: function (ref) {
                _this._componentRef = ref;
            } }));
    };
    FloatingItem.prototype._addPlayerBindings = function () {
        var _a = this._options, kalturaPlayer = _a.kalturaPlayer, data = _a.data, eventManager = _a.eventManager;
        if (data.mode === 'MediaLoaded') {
            eventManager.listenOnce(kalturaPlayer, kalturaPlayer.Event.Core.MEDIA_LOADED, this.add);
        }
        if (data.mode === 'FirstPlay') {
            eventManager.listenOnce(kalturaPlayer, kalturaPlayer.Event.Core.FIRST_PLAY, this.add);
        }
        if (data.mode === 'Immediate') {
            this.add();
        }
    };
    return FloatingItem;
}());
exports.FloatingItem = FloatingItem;
