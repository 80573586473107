"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SomeoneAsksSmall = void 0;
var preact_1 = require("preact");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var SomeoneAsksSmall = function (props) { return ((0, preact_1.h)("svg", __assign({ width: "20px", height: "20px", viewBox: "0 0 20 20", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
    (0, preact_1.h)("g", { id: "Icons/20/Someone-asks", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
        (0, preact_1.h)("g", { id: "Group", transform: "translate(2.000000, 2.000000)", fill: "#B2D238" },
            (0, preact_1.h)("circle", { id: "Oval", cx: "8", cy: "4", r: "4" }),
            (0, preact_1.h)("path", { d: "M8,9 C5.33,9 0,10.5633333 0,13.6666667 L0,14.8333333 C0,15.475 0.45,16 1,16 L15,16 C15.55,16 16,15.475 16,14.8333333 L16,13.6666667 C16,10.5633333 10.67,9 8,9 Z", id: "Path" }))))); };
exports.SomeoneAsksSmall = SomeoneAsksSmall;
