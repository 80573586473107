// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._managed-component__fill-container___BZ9jB{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./../node_modules/@playkit-js/common/dist/ui-common/managed-component/_managed-component.scss"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,WAAA","sourcesContent":[".fill-container {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill-container": "_managed-component__fill-container___BZ9jB",
	"fillContainer": "_managed-component__fill-container___BZ9jB"
};
export default ___CSS_LOADER_EXPORT___;
