// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".injected-component__fill-container___mNczr{width:100%;height:100%;overflow:hidden}", "",{"version":3,"sources":["webpack://./../node_modules/@playkit-js/common/dist/ui-common/injected-component/injected-component.scss"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".fill-container {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill-container": "injected-component__fill-container___mNczr",
	"fillContainer": "injected-component__fill-container___mNczr"
};
export default ___CSS_LOADER_EXPORT___;
