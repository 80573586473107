"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownBar = void 0;
var preact_1 = require("preact");
var styles = __importStar(require("./dropdown-bar.component.scss"));
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var a11y_wrapper_1 = require("@playkit-js/common/dist/hoc/a11y-wrapper");
var Icon = kaltura_player_js_1.ui.Components.Icon;
var DropdownBar = /** @class */ (function (_super) {
    __extends(DropdownBar, _super);
    function DropdownBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DropdownBar.prototype.render = function () {
        var _this = this;
        return ((0, preact_1.h)("div", { className: styles.moreDropdown, role: "menu", "aria-expanded": "true" }, this.props.controls.map(function (_a) {
            var id = _a.id, displayName = _a.displayName, ariaLabel = _a.ariaLabel, svgIcon = _a.svgIcon, onClick = _a.onClick;
            return ((0, preact_1.h)(preact_1.Fragment, { key: id },
                (0, preact_1.h)(a11y_wrapper_1.A11yWrapper, { onClick: function (e) {
                        onClick(e);
                        _this.props.onDropdownClick();
                    }, role: "menuitem" },
                    (0, preact_1.h)("div", { className: styles.dropdownItem, tabIndex: 0, "aria-label": ariaLabel },
                        (0, preact_1.h)("div", { className: styles.icon },
                            (0, preact_1.h)(Icon, { id: displayName, path: svgIcon.path, viewBox: svgIcon.viewBox || '0 0 32 32' })),
                        (0, preact_1.h)("span", { className: styles.dropdownItemDescription }, displayName)))));
        })));
    };
    return DropdownBar;
}(preact_1.Component));
exports.DropdownBar = DropdownBar;
