"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidePanelsManager = void 0;
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var item_wrapper_1 = require("./models/item-wrapper");
var SidePanelModes = kaltura_player_js_1.ui.SidePanelModes, SidePanelPositions = kaltura_player_js_1.ui.SidePanelPositions, ReservedPresetNames = kaltura_player_js_1.ui.ReservedPresetNames;
var COUNTER_PANELS = (_a = {},
    _a[SidePanelPositions.TOP] = SidePanelPositions.BOTTOM,
    _a[SidePanelPositions.BOTTOM] = SidePanelPositions.TOP,
    _a[SidePanelPositions.RIGHT] = SidePanelPositions.LEFT,
    _a[SidePanelPositions.LEFT] = SidePanelPositions.RIGHT,
    _a);
var SidePanelsManager = /** @class */ (function () {
    /**
     * @ignore
     */
    function SidePanelsManager(player, logger) {
        this.player = player;
        this.activePanels = { top: null, bottom: null, right: null, left: null };
        this.componentsRegistry = new Map();
        this.logger = logger;
    }
    SidePanelsManager.prototype.add = function (item) {
        if (SidePanelsManager.validateItem(item)) {
            var newItemWrapper = new item_wrapper_1.ItemWrapper(item, this.player);
            this.componentsRegistry.set(newItemWrapper.id, newItemWrapper);
            this.logger.debug('New Panel Item Added', item);
            return newItemWrapper.id;
        }
        this.logger.error('Invalid SidePanelItem parameters', item);
    };
    SidePanelsManager.prototype.remove = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            if (this.isItemActive(itemId))
                this.deactivateItem(itemId);
            itemWrapper.remove();
            this.componentsRegistry.delete(itemId);
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    SidePanelsManager.prototype.activateItem = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            var _a = itemWrapper.item, position = _a.position, expandMode = _a.expandMode;
            // Trying to activate an already active item
            if (this.isItemActive(itemId))
                return;
            // Switch between items if currently there is an active one (without collapsing / expanding PS)
            var previousItemWrapper = this.activePanels[position];
            if (previousItemWrapper !== null) {
                previousItemWrapper.deactivate(true);
            }
            // Deactivate the counter panel if is active
            var counterPosition = SidePanelsManager.getCounterPanelPosition(position);
            if (this.activePanels[counterPosition]) {
                this.deactivateItem(this.activePanels[counterPosition].id);
            }
            // Update new item as active
            itemWrapper.activate();
            this.expand(position, expandMode);
            this.activePanels[position] = itemWrapper;
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    SidePanelsManager.prototype.deactivateItem = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            if (!this.isItemActive(itemId))
                return;
            var position = itemWrapper.item.position;
            itemWrapper.deactivate();
            this.collapse(position);
            this.activePanels[position] = null;
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    SidePanelsManager.prototype.isItemActive = function (itemId) {
        var _a;
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            return ((_a = this.activePanels[itemWrapper.item.position]) === null || _a === void 0 ? void 0 : _a.id) === itemId;
        }
        this.logger.warn("".concat(itemId, " is not registered"));
        return false;
    };
    SidePanelsManager.prototype.isItemDetached = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            return itemWrapper.isDetached;
        }
        this.logger.warn("".concat(itemId, " is not registered"));
        return false;
    };
    SidePanelsManager.prototype.detachItem = function (itemId, options) {
        var _this = this;
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            this.deactivateItem(itemId);
            itemWrapper.detach(__assign(__assign({}, options), { onDetachWindowClose: function () {
                    // detach window closed by system
                    _this.attachItem(itemId);
                    options === null || options === void 0 ? void 0 : options.onDetachWindowClose();
                } }));
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    SidePanelsManager.prototype.attachItem = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            itemWrapper.attach();
            this.activateItem(itemId);
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    SidePanelsManager.prototype.getDetachedRef = function (itemId) {
        if (this.isItemDetached(itemId)) {
            var itemWrapper = this.componentsRegistry.get(itemId);
            return itemWrapper.getDetachedRef();
        }
        return null;
    };
    /**
     * Rerender (uses preact Component.forceUpdate api under the hoods) the side panel item component
     * It's just for backward compatibility you should not use it.
     */
    SidePanelsManager.prototype.update = function (itemId) {
        var itemWrapper = this.componentsRegistry.get(itemId);
        if (itemWrapper) {
            itemWrapper.update();
        }
        else {
            this.logger.warn("".concat(itemId, " is not registered"));
        }
    };
    /**
     * @ignore
     */
    SidePanelsManager.prototype.reset = function () {
        this.logger.debug('reset');
    };
    /**
     * @ignore
     */
    SidePanelsManager.prototype.destroy = function () {
        this.logger.debug('destroy');
        this.removeAllItems();
    };
    SidePanelsManager.prototype.removeAllItems = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.componentsRegistry.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var value = _c.value;
                this.remove(value.id);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    SidePanelsManager.prototype.toggle = function (itemId) {
        if (this.isItemActive(itemId)) {
            this.deactivateItem(itemId);
        }
        else {
            this.activateItem(itemId);
        }
    };
    SidePanelsManager.prototype.expand = function (position, expandMode) {
        this.player.ui.store.dispatch(kaltura_player_js_1.ui.reducers.shell.actions.updateSidePanelMode(position, expandMode));
    };
    SidePanelsManager.prototype.collapse = function (position) {
        this.player.ui.store.dispatch(kaltura_player_js_1.ui.reducers.shell.actions.updateSidePanelMode(position, SidePanelModes.HIDDEN));
    };
    SidePanelsManager.getCounterPanelPosition = function (position) {
        return COUNTER_PANELS[position];
    };
    SidePanelsManager.validateItem = function (item) {
        var label = item.label, panelComponent = item.panelComponent, position = item.position, expandMode = item.expandMode, presets = item.presets;
        return !!(label &&
            Object.values(SidePanelPositions).includes(position) &&
            Object.values(SidePanelModes).includes(expandMode) &&
            presets.every(function (preset) { return Object.values(ReservedPresetNames).includes(preset); }) &&
            typeof panelComponent === 'function');
    };
    return SidePanelsManager;
}());
exports.SidePanelsManager = SidePanelsManager;
