"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DETACH_POSITION_INTERVAL = exports.CLOSE_DETACH_EVENTS = exports.DETACH_CONTAINER_CLASS = exports.DETACHED_WINDOW_STYLES = void 0;
exports.DETACHED_WINDOW_STYLES = {
    margin: '0px',
    backgroundColor: '#000'
};
exports.DETACH_CONTAINER_CLASS = 'playkit-player detach-sidebar-container';
exports.CLOSE_DETACH_EVENTS = ['beforeunload', 'popstate'];
exports.DETACH_POSITION_INTERVAL = 1000;
