"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoreIcon = void 0;
var preact_1 = require("preact");
var a11y_wrapper_1 = require("@playkit-js/common/dist/hoc/a11y-wrapper");
var kaltura_player_js_1 = require("@playkit-js/kaltura-player-js");
var styles = __importStar(require("./more-icon.component.scss"));
var dropdown_bar_component_1 = require("../dropdown-bar/dropdown-bar.component");
var ui_managers_1 = require("../../../../ui-managers");
var _a = kaltura_player_js_1.ui.Components, Icon = _a.Icon, Tooltip = _a.Tooltip;
var withEventManager = kaltura_player_js_1.ui.Event.withEventManager;
var _b = kaltura_player_js_1.ui.preacti18n, withText = _b.withText, Text = _b.Text;
var ICON_PATH = 
// eslint-disable-next-line max-len
'M16 22a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-11a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-11a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z';
var MoreIcon = /** @class */ (function (_super) {
    __extends(MoreIcon, _super);
    function MoreIcon() {
        var _this = _super.call(this) || this;
        _this.moreButtonRef = (0, preact_1.createRef)();
        return _this;
    }
    MoreIcon.prototype.componentDidMount = function () {
        var _this = this;
        this.props.eventManager.listen(document, 'click', function (e) { return _this.handleClickOutside(e); });
    };
    MoreIcon.prototype.handleClickOutside = function (event) {
        if (this.moreButtonRef && !this.moreButtonRef.current.contains(event.target)) {
            this.setState({ toggle: false });
        }
    };
    MoreIcon.prototype.render = function () {
        return ((0, preact_1.h)("div", { style: { position: 'relative' } },
            (0, preact_1.h)(Tooltip, { label: this.props.moreIconTxt },
                (0, preact_1.h)(a11y_wrapper_1.A11yWrapper, { onClick: this.props.onClick },
                    (0, preact_1.h)("button", { ref: this.moreButtonRef, className: "".concat(kaltura_player_js_1.ui.style.upperBarIcon, " ").concat(styles.moreIcon), tabIndex: 0, "aria-label": this.props.moreIconTxt },
                        (0, preact_1.h)(Icon, { id: "".concat(ui_managers_1.pluginName, "-upper-bar-manager"), path: ICON_PATH, viewBox: '0 0 32 32' })))),
            this.props.showDropdown && ((0, preact_1.h)("div", null,
                (0, preact_1.h)(dropdown_bar_component_1.DropdownBar, { onDropdownClick: this.props.onClick, controls: this.props.icons })))));
    };
    MoreIcon = __decorate([
        withEventManager,
        withText({ moreIconTxt: (0, preact_1.h)(Text, { id: "controls.moreIcon" }, "More") })
    ], MoreIcon);
    return MoreIcon;
}(preact_1.Component));
exports.MoreIcon = MoreIcon;
