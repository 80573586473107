"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerManager = exports.VisibilityMode = void 0;
var preact_1 = require("preact");
var banner_1 = require("./ui/banner");
var banner_container_1 = require("./ui/banner-container/banner-container");
var player_utils_1 = require("@playkit-js/common/dist/ui-common/player-utils");
var VisibilityMode;
(function (VisibilityMode) {
    VisibilityMode["VISIBLE"] = "VISIBLE";
    VisibilityMode["HIDDEN"] = "HIDDEN";
})(VisibilityMode = exports.VisibilityMode || (exports.VisibilityMode = {}));
var MinPlayerWidth = 480;
var DefaultDuration = 60 * 1000;
var MinDuration = 5 * 1000;
/**
 * banner manager manages the display (add / remove) of a single banner in the player.
 */
var BannerManager = /** @class */ (function () {
    function BannerManager(options) {
        this.options = options;
        this._floatingItem = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._timerSubscription = undefined;
        this._options = options;
        this._bannerConfig = {
            theme: {
                backgroundColor: 'rgba(0, 0, 0, .7)',
                blur: '10px'
            }
        };
    }
    BannerManager.prototype.add = function (props) {
        if (this._floatingItem) {
            this.remove();
        }
        this._floatingItem = this._options.floatingManager.add({
            label: 'Banner',
            mode: 'Immediate',
            position: 'InteractiveArea',
            renderContent: this._createRenderBanner(props, {
                onClose: this._handleCloseEvent.bind(this),
                theme: this._bannerConfig.theme
            })
        });
        if (props.autoClose) {
            this._startDurationTimer(props.duration);
        }
        return this._getState();
    };
    BannerManager.prototype.remove = function () {
        if (this._floatingItem) {
            if (this._timerSubscription)
                clearTimeout(this._timerSubscription);
            this._options.floatingManager.remove(this._floatingItem);
            this._floatingItem = null;
        }
    };
    BannerManager.prototype.reset = function () {
        this.remove();
    };
    BannerManager.prototype._createRenderBanner = function (_a, _b) {
        var content = _a.content, renderContent = _a.renderContent;
        var onClose = _b.onClose, theme = _b.theme;
        function _renderContent(floatingItemProps) {
            return ((0, preact_1.h)(banner_container_1.BannerContainer, { onClose: onClose, theme: theme }, renderContent ? renderContent(content, floatingItemProps) : (0, preact_1.h)(banner_1.Banner, { content: content })));
        }
        return _renderContent;
    };
    BannerManager.prototype._handleCloseEvent = function () {
        this.remove();
    };
    BannerManager.prototype._startDurationTimer = function (displayDuration) {
        if (displayDuration === void 0) { displayDuration = DefaultDuration; }
        this._timerSubscription = setTimeout(this.remove.bind(this), Math.max(MinDuration, displayDuration));
    };
    BannerManager.prototype._getState = function () {
        var playerSize = (0, player_utils_1.getPlayerSize)(this._options.kalturaPlayer);
        return {
            visibilityMode: !playerSize || playerSize.width < MinPlayerWidth ? VisibilityMode.HIDDEN : VisibilityMode.VISIBLE
        };
    };
    return BannerManager;
}());
exports.BannerManager = BannerManager;
