"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconWrapper = void 0;
var preact_1 = require("preact");
var a11y_wrapper_1 = require("@playkit-js/common/dist/hoc/a11y-wrapper");
var IconWrapper = /** @class */ (function (_super) {
    __extends(IconWrapper, _super);
    function IconWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IconWrapper.prototype.render = function () {
        return ((0, preact_1.h)(a11y_wrapper_1.A11yWrapper, { role: "generic", onClick: this.props.onClick },
            (0, preact_1.h)("div", null, (0, preact_1.cloneElement)(this.props.children))));
    };
    return IconWrapper;
}(preact_1.Component));
exports.IconWrapper = IconWrapper;
