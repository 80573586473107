(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@playkit-js/playkit-js"));
	else if(typeof define === 'function' && define.amd)
		define("playkit", ["@playkit-js/playkit-js"], factory);
	else if(typeof exports === 'object')
		exports["playkit"] = factory(require("@playkit-js/playkit-js"));
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["ui"] = factory(root["playkit"]["core"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__230__) {
return 