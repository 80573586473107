"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemWrapper = void 0;
var preact_1 = require("preact");
var panel_item_wrapper_component_1 = require("../ui/panel-item-wrapper/panel-item-wrapper.component");
var side_panel_item_1 = require("./side-panel-item");
/**
 * Panel item metadata
 * @internal
 */
var ItemWrapper = /** @class */ (function () {
    function ItemWrapper(item, player) {
        var _this = this;
        this._detachWindow = null;
        this._closingDetachWindow = false;
        this._attachingDetachWindow = false;
        this._detachWindowPosition = { screenX: 0, screenY: 0 };
        this._detachWindowSize = { innerWidth: 0, innerHeight: 0 };
        this._detachWindowAnalyticsInterval = null;
        this._initialDetachWindowSizeSet = false;
        this.attach = function () {
            if (_this.isDetached) {
                _this._attachingDetachWindow = true;
                _this.panelItemComponentRef.current.attach();
                _this._closeDetachedWindow();
            }
        };
        this._setDetachWindowPosition = function (x, y) {
            _this._detachWindowPosition = { screenX: x, screenY: y };
        };
        this._setDetachWindowSize = function (width, height) {
            _this._detachWindowSize = { innerWidth: width, innerHeight: height };
        };
        this._closeDetachedWindow = function () {
            if (!_this._detachWindow || _this._closingDetachWindow) {
                return;
            }
            _this._closingDetachWindow = true;
            side_panel_item_1.CLOSE_DETACH_EVENTS.forEach(function (closeEvent) {
                window.removeEventListener(closeEvent, _this._closeDetachedWindow);
            });
            _this._detachWindow.close();
            _this._detachWindow = null;
            _this._closingDetachWindow = false;
            _this._attachingDetachWindow = false;
            _this._initialDetachWindowSizeSet = false;
            _this._setDetachWindowPosition(0, 0);
            _this._setDetachWindowSize(0, 0);
            if (_this._detachWindowAnalyticsInterval) {
                clearInterval(_this._detachWindowAnalyticsInterval);
                _this._detachWindowAnalyticsInterval = null;
            }
        };
        this.id = ++ItemWrapper.nextId;
        this.item = item;
        this.player = player;
        this.isActive = false;
        this.injectPanelComponent();
    }
    ItemWrapper.prototype.activate = function () {
        var _this = this;
        if (this.panelItemComponentRef.current) {
            this.panelItemComponentRef.current.on();
            this.isActive = true;
        }
        else {
            setTimeout(function () { return _this.activate(); });
        }
    };
    ItemWrapper.prototype.deactivate = function (switchMode) {
        var _a;
        if (switchMode === void 0) { switchMode = false; }
        (_a = this.panelItemComponentRef.current) === null || _a === void 0 ? void 0 : _a.off(switchMode);
        this.isActive = false;
    };
    ItemWrapper.prototype.detach = function (options) {
        var _this = this;
        var _a, _b;
        var el = document.createElement('div');
        el.style.width = '100%';
        el.style.height = "100%";
        el.className = "".concat(side_panel_item_1.DETACH_CONTAINER_CLASS, "-").concat(this.id);
        // create and set params to the new window
        var newWindowParams = 'menubar=no,status=no,location=no,toolbar=no';
        newWindowParams += ",width=".concat((options === null || options === void 0 ? void 0 : options.width) || 'auto', ",height=").concat((options === null || options === void 0 ? void 0 : options.height) || 'auto');
        newWindowParams += ",top=".concat((options === null || options === void 0 ? void 0 : options.top) || 'auto', ", left=").concat((options === null || options === void 0 ? void 0 : options.left) || 'auto');
        this._detachWindow = window.open('', '_blank', newWindowParams);
        this._detachWindow.document.title = options === null || options === void 0 ? void 0 : options.title;
        (_a = this._detachWindow) === null || _a === void 0 ? void 0 : _a.focus();
        // copy and set styles to the new window
        var currentPageHead = document.head;
        var newPageHead = this._detachWindow.document.head;
        var newPageBody = this._detachWindow.document.body;
        var styles = currentPageHead.querySelectorAll('style');
        styles.forEach(function (style) {
            var newStyle = _this._detachWindow.document.createElement('style');
            newStyle.textContent = style.textContent;
            newPageHead.appendChild(newStyle);
        });
        Object.assign(newPageBody.style, side_panel_item_1.DETACHED_WINDOW_STYLES);
        // Append the <div> element to the new window's document
        (_b = this._detachWindow) === null || _b === void 0 ? void 0 : _b.document.body.appendChild(el);
        // handle close of new window
        this._detachWindow.onbeforeunload = function () {
            if (_this._attachingDetachWindow) {
                return;
            }
            options.onDetachWindowClose();
            _this._closeDetachedWindow();
        };
        side_panel_item_1.CLOSE_DETACH_EVENTS.forEach(function (closeEvent) {
            window.addEventListener(closeEvent, _this._closeDetachedWindow);
        });
        // handle resize of new window
        if ((options === null || options === void 0 ? void 0 : options.maxWidth) || (options === null || options === void 0 ? void 0 : options.maxHeight)) {
            this._detachWindow.addEventListener('resize', function (event) {
                event.preventDefault();
                if ((options === null || options === void 0 ? void 0 : options.maxWidth) && _this._detachWindow.innerWidth > options.maxWidth) {
                    _this._detachWindow.resizeTo(options.maxWidth, _this._detachWindow.outerHeight);
                }
                if ((options === null || options === void 0 ? void 0 : options.maxHeight) && _this._detachWindow.innerHeight > options.maxHeight) {
                    _this._detachWindow.resizeTo(_this._detachWindow.outerWidth, options.maxHeight);
                }
            });
        }
        // handle window move and resize
        if (options.onDetachMove || options.onDetachResize) {
            var _c = this._detachWindow, screenX_1 = _c.screenX, screenY_1 = _c.screenY;
            this._setDetachWindowPosition(screenX_1, screenY_1);
            // use interval since there no handlers for new window position change
            this._detachWindowAnalyticsInterval = setInterval(function () {
                if (!_this._initialDetachWindowSizeSet) {
                    _this._setDetachWindowSize(_this._detachWindow.innerWidth, _this._detachWindow.innerHeight);
                    _this._initialDetachWindowSizeSet = true;
                }
                if (options.onDetachMove &&
                    (_this._detachWindow.screenX !== _this._detachWindowPosition.screenX ||
                        _this._detachWindow.screenY !== _this._detachWindowPosition.screenY)) {
                    _this._setDetachWindowPosition(_this._detachWindow.screenX, _this._detachWindow.screenY);
                    options.onDetachMove(_this._detachWindow.screenX, _this._detachWindow.screenY);
                }
                if (options.onDetachResize &&
                    (_this._detachWindow.innerWidth !== _this._detachWindowSize.innerWidth ||
                        _this._detachWindow.innerHeight !== _this._detachWindowSize.innerHeight)) {
                    _this._setDetachWindowSize(_this._detachWindow.innerWidth, _this._detachWindow.innerHeight);
                    options.onDetachResize(_this._detachWindow.innerWidth, _this._detachWindow.innerHeight);
                }
            }, side_panel_item_1.DETACH_POSITION_INTERVAL);
        }
        this.panelItemComponentRef.current.detach(el, (options === null || options === void 0 ? void 0 : options.attachPlaceholder) || (function () { return null; }));
    };
    Object.defineProperty(ItemWrapper.prototype, "isDetached", {
        get: function () {
            return Boolean(this._detachWindow);
        },
        enumerable: false,
        configurable: true
    });
    ItemWrapper.prototype.getDetachedRef = function () {
        var _a;
        return (_a = this.panelItemComponentRef.current) === null || _a === void 0 ? void 0 : _a.detachRef;
    };
    ItemWrapper.prototype.remove = function () {
        this.removePanelComponentFn();
        this._closeDetachedWindow();
    };
    ItemWrapper.prototype.update = function () {
        var _a;
        (_a = this.panelItemComponentRef.current) === null || _a === void 0 ? void 0 : _a.forceUpdate();
    };
    ItemWrapper.prototype.injectPanelComponent = function () {
        var _this = this;
        var _a = this.item, label = _a.label, position = _a.position, panelComponent = _a.panelComponent, presets = _a.presets;
        var SidePanelComponent = panelComponent;
        var componentRef = (0, preact_1.createRef)();
        this.panelItemComponentRef = componentRef;
        this.removePanelComponentFn = this.player.ui.addComponent({
            label: "Side-panel-".concat(position, "-").concat(label),
            presets: presets,
            area: ItemWrapper.getPanelArea(position),
            get: function () {
                return ((0, preact_1.h)(panel_item_wrapper_component_1.PanelItemWrapper, { ref: componentRef },
                    (0, preact_1.h)(SidePanelComponent, { isActive: _this.isActive })));
            }
        });
    };
    ItemWrapper.getPanelArea = function (position) {
        return "SidePanel".concat(position.charAt(0).toUpperCase()).concat(position.slice(1));
    };
    ItemWrapper.nextId = 0;
    return ItemWrapper;
}());
exports.ItemWrapper = ItemWrapper;
