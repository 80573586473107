(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@playkit-js/playkit-js"), require("shaka-player"));
	else if(typeof define === 'function' && define.amd)
		define("playkit", ["playkit-js", "shaka-player"], factory);
	else if(typeof exports === 'object')
		exports["playkit"] = factory(require("@playkit-js/playkit-js"), require("shaka-player"));
	else
		root["playkit"] = root["playkit"] || {}, root["playkit"]["dash"] = factory(root["playkit"]["core"], root["shaka"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__952__, __WEBPACK_EXTERNAL_MODULE__475__) {
return 