"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SomeoneAsksLarge = void 0;
var preact_1 = require("preact");
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var SomeoneAsksLarge = function (props) { return ((0, preact_1.h)("svg", __assign({ width: "32px", height: "32px", viewBox: "0 0 32 32", version: "1.1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink" }, props),
    (0, preact_1.h)("g", { id: "Icons/32/Someone-asks", stroke: "none", "stroke-width": "1", fill: "none", "fill-rule": "evenodd" },
        (0, preact_1.h)("g", { id: "Group", transform: "translate(4.000000, 5.000000)", fill: "#B2D238" },
            (0, preact_1.h)("ellipse", { id: "Oval", cx: "12", cy: "5.5", rx: "6", ry: "5.5" }),
            (0, preact_1.h)("path", { d: "M12,13 C7.995,13 0,15.01 0,19 L0,20.5 C0,21.325 0.675,22 1.5,22 L22.5,22 C23.325,22 24,21.325 24,20.5 L24,19 C24,15.01 16.005,13 12,13 Z", id: "Path" }))))); };
exports.SomeoneAsksLarge = SomeoneAsksLarge;
